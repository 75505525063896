<template>
  <b-card>
    <b-form class="mt-2" @submit.prevent="saveChange">
      <b-row>
        <b-col sm="12">
          <b-form-group :label="$i18n.t('Status do robô')" label-for="status">
            <b-form-input v-model="status" name="status" />
            <small class="text-warning">0 - para desativado | 1 - para ativado</small>
          </b-form-group>
          <b-form-group :label="$i18n.t('Objetivo de ganho diário por dia do mês')">
            <b-table :items="dailyGoalsArray" :fields="goalFields" bordered responsive>
              <template #cell(goal)="data">
                <b-form-input v-model="data.item.goal"></b-form-input>
              </template>
            </b-table>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button type="submit" variant="primary" class="mt-2 mr-1" :disabled="loading">
            <div v-if="!loading">
              {{ $t("Salvar alterações") }}
            </div>
            <div v-else>
              <b-spinner small type="grow"></b-spinner>
              {{ $t("Carregando...") }}
            </div>
          </b-button>
          <b-button variant="outline-secondary" type="reset" class="mt-2" @click.prevent="resetForm">
            {{ $t("Redefinir") }}
          </b-button>
          <alert class="mt-1" :text="textAlert" :color="colorAlert" :showAlert="showAlert"
            @changeVariableAlert="(val) => (showAlert = val)" />
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTable,
  BButton,
  BSpinner,
} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    BSpinner,
    // Certifique-se de que o componente 'alert' está importado ou registrado globalmente
  },
  data() {
    return {
      robotName: "SatoBot",
      idRobot: "",
      profit: 0.001,
      hitHate: 70,
      amountTrade: 3,
      status: 0,
      dailyGoals: {}, // Objeto com os objetivos diários
      dailyGoalsArray: [], // Array para uso na tabela
      goalFields: [
        { key: 'day', label: 'Dia' },
        { key: 'goal', label: 'Objetivo de ganho (%)' }
      ],
      loading: false,
      showAlert: false,
      textAlert: "",
      colorAlert: "warning",
    };
  },
  mounted() {
    this.getRobotConfig();
  },
  methods: {
    initializeDailyGoals() {
      for (let i = 1; i <= 31; i++) {
        this.dailyGoals[i] = 1.5; // Valor padrão
      }
      this.updateDailyGoalsArray();
    },
    updateDailyGoalsArray() {
      this.dailyGoalsArray = Object.keys(this.dailyGoals).map(day => ({
        day: parseInt(day),
        goal: this.dailyGoals[day],
      }));
    },
    getRobotConfig() {
      this.$store.dispatch('searchConfigRobot').then(resp => {
        const config = resp.config[0];
        this.idRobot = config._id;
        this.profit = config.profit;
        this.hitHate = config.hitHate;
        this.amountTrade = config.amountTrade;
        this.status = config.status;

        if (config.dailyGoals && Object.keys(config.dailyGoals).length > 0) {
          this.dailyGoals = config.dailyGoals;
        } else {
          this.initializeDailyGoals();
        }
        this.updateDailyGoalsArray();
      });
    },
    saveChange() {
      this.loading = true;

      // Converter dailyGoalsArray de volta para dailyGoals
      this.dailyGoals = {};
      this.dailyGoalsArray.forEach(item => {
        this.dailyGoals[item.day] = parseFloat(item.goal);
      });

      const config = {
        idRobot: this.idRobot,
        profit: this.profit,
        hitHate: this.hitHate,
        amountTrade: this.amountTrade,
        dailyGoals: this.dailyGoals,
        status: this.status,
      };

      this.$store
        .dispatch("changeConfigRobot", config)
        .then(() => {
          this.showAlert = true;
          this.colorAlert = "success";
          this.textAlert = this.$i18n.t(
            "Configurações do robô trader atualizadas."
          );
          this.loading = false;
        })
        .catch((err) => {
          this.showAlert = true;
          this.colorAlert = "danger";
          this.textAlert = err;
          this.loading = false;
        });
    },
    resetForm() {
      this.getRobotConfig();
    },
  },
};
</script>
